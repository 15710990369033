<template>
  <ui-component-modal
    modalTitle="Edit invoice address"
    :onClickCancel="onClickCancel"
    :onClickSave="saveCartInvoiceAddress"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :savingSuccessMessage="'Data saved'"
    :savingErrorMessage="'Something went wrong'"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div v-if="!isSavingError && !isSavingSuccess">
        <component
          v-for="(formfield, index) in formFields"
          :is="formfield.component"
          :key="'formField' + index"
          :label="formfield.label"
          :fieldType="formfield.fieldType"
          v-model="formfield.value"
          :error="formfield.error"
        ></component>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import cartProvider from '@/providers/cart'
import { EventBus } from '@/eventbus/event-bus'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isSavingError: false,
      isSavingSuccess: false,
      isSaving: false,
      formFields: []
    }
  },

  computed: {
    ...mapState('cartStore', ['cart'])
  },

  mounted() {
    this.setFormFields()
  },

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    setFormFields() {
      this.formFields = {
        sendToName: {
          component: 'ui-base-input-field',
          label: 'Send to',
          fieldType: 'text',
          value: this.cart.InvoiceAddress.SendTo,
          error: '',
          required: true
        },
        sendToEmail: {
          component: 'ui-base-input-field',
          label: 'E-mail',
          fieldType: 'email',
          value: this.cart.InvoiceAddress.Email,
          error: '',
          required: true
        },
        poNumber: {
          component: 'ui-base-input-field',
          label: 'Po number',
          fieldType: 'text',
          value: this.cart.InvoiceAddress.PoNumber,
          error: '',
          required: false
        },
        address: {
          component: 'ui-base-input-field',
          label: 'Address',
          fieldType: 'text',
          value: this.cart.InvoiceAddress.Address,
          error: '',
          required: false
        },
        postalCode: {
          component: 'ui-base-input-field',
          label: 'Postal code',
          fieldType: 'text',
          value: this.cart.InvoiceAddress.PostalCode,
          error: '',
          required: false
        },
        city: {
          component: 'ui-base-input-field',
          label: 'City',
          fieldType: 'text',
          value: this.cart.InvoiceAddress.City,
          error: '',
          required: false
        },
        country: {
          component: 'ui-base-input-field',
          label: 'Country',
          fieldType: 'text',
          value: this.cart.InvoiceAddress.Country,
          error: '',
          required: false
        },
        phone: {
          component: 'ui-base-input-field',
          label: 'Phone',
          fieldType: 'text',
          value: this.cart.InvoiceAddress.PhoneNumber,
          error: '',
          required: false
        }
      }
    },

    saveCartInvoiceAddress() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        let invoiceAddress = {
          Address: self.formFields.address.value,
          PostalCode: self.formFields.postalCode.value,
          City: self.formFields.city.value,
          Country: self.formFields.country.value,
          // State: this.formFields.address.value,
          SendTo: self.formFields.sendToName.value,
          Email: self.formFields.sendToEmail.value,
          PoNumber: self.formFields.poNumber.value,
          PhoneNumber: self.formFields.phone.value
        }

        cartProvider.methods
          .updateCartInvoiceAddres(self.cart.CartKey, invoiceAddress)
          .then(response => {
            if (response.status === 200) {
              self.setCart(response.data)

              self.isSavingSuccess = true

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch(error => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    }
  }
}
</script>
